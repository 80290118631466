.login
{
    padding: 24px;
    width: 330px;
    height: 300px;
    background-color: white;
    position: relative;
    left: calc(50% - 165px);
    top: calc(50% - 277px);
    margin-top: 77px;

    label{
        font-weight: normal !important;
    }

    span{
        border-radius: 0;
    }

    path {
        fill: #1890ff;
    }

    button[type="submit"]:hover {
        background-color: #42baf9 !important;
        border-color: inherit !important;
    }

    button[type="submit"] span {
        color: white !important;
    }
}