.promocodes__tab{

    .ant-table-cell > span {
        margin-right: 10px;
        height: 40px;
        line-height: 40px;
    }

    .ant-typography{
        margin: 0;
    }
}