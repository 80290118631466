@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-Light.eot');
    src: local('Cera Pro Light'), local('CeraPro-Light'),
        url('./assets/fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-Light.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-Light.woff') format('woff'),
        url('./assets/fonts/CeraPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-MediumItalic.eot');
    src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic'),
        url('./assets/fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-MediumItalic.woff') format('woff'),
        url('./assets/fonts/CeraPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-RegularItalic.eot');
    src: local('Cera Pro Regular Italic'), local('CeraPro-RegularItalic'),
        url('./assets/fonts/CeraPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-RegularItalic.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-RegularItalic.woff') format('woff'),
        url('./assets/fonts/CeraPro-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-BlackItalic.eot');
    src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic'),
        url('./assets/fonts/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-BlackItalic.woff') format('woff'),
        url('./assets/fonts/CeraPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-Medium.eot');
    src: local('Cera Pro Medium'), local('CeraPro-Medium'),
        url('./assets/fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-Medium.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-Medium.woff') format('woff'),
        url('./assets/fonts/CeraPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-BoldItalic.eot');
    src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic'),
        url('./assets/fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-BoldItalic.woff') format('woff'),
        url('./assets/fonts/CeraPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-ThinItalic.eot');
    src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic'),
        url('./assets/fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-ThinItalic.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-ThinItalic.woff') format('woff'),
        url('./assets/fonts/CeraPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-LightItalic.eot');
    src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic'),
        url('./assets/fonts/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-LightItalic.woff') format('woff'),
        url('./assets/fonts/CeraPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-Black.eot');
    src: local('Cera Pro Black'), local('CeraPro-Black'),
        url('./assets/fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-Black.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-Black.woff') format('woff'),
        url('./assets/fonts/CeraPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-Bold.eot');
    src: local('Cera Pro Bold'), local('CeraPro-Bold'),
        url('./assets/fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-Bold.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-Bold.woff') format('woff'),
        url('./assets/fonts/CeraPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-Regular.eot');
    src: local('Cera Pro Regular'), local('CeraPro-Regular'),
        url('./assets/fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-Regular.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-Regular.woff') format('woff'),
        url('./assets/fonts/CeraPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('./assets/fonts/CeraPro-Thin.eot');
    src: local('Cera Pro Thin'), local('CeraPro-Thin'),
        url('./assets/fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CeraPro-Thin.woff2') format('woff2'),
        url('./assets/fonts/CeraPro-Thin.woff') format('woff'),
        url('./assets/fonts/CeraPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

* {
	font-family: 'Cera Pro';
	font-style: normal;
	font-size: 16px;
	font-weight: normal;
	line-height: 22px;
	letter-spacing: 0px;

}

html, body, #root{
	height: 100%;
    overflow-x: hidden;
    background: linear-gradient(rgb(72, 88, 102), rgb(180, 219, 255));
}

a{
    color: #1890FF !important;
}

section
{
    background-color: #f0f2f5 !important;
}

.breadcrumbs
{
    width: 100%;
}

.ant-table-thead th
{
    color: #262626 !important;
    background-color: #FAFAFA !important;
}

.ant-table-thead th::before
{
    content: none !important;
}

.ant-dropdown-menu{
    border-radius: 4px !important;
    background-color: #fff !important;
}

.ant-dropdown-menu a{
    color: #262626 !important;
}

.anticon.text-red > svg{
    fill: #FF4D4F !important;
}

.ant-dropdown-menu a.text-red{
    color: #FF4D4F !important;
}

.ant-pagination-options{
    display: none !important;
}

.ant-btn-primary{
    box-shadow: unset !important;
    background-color: #1677ff !important;
}

.ant-btn-primary *{
    color: #fff !important;
}

.ant-input:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.97) !important;
}

.ant-form-item-required::before {
	display: none !important;
}

.ant-form-item-explain-error{
	white-space: nowrap;
	font-size: 14px;
}

.anticon-eye{
	color: rgb(6 6 6 / 45%) !important;
}

.anticon-eye:hover{
	color: rgb(6 6 6 / 60%) !important;
}

.anticon-eye-invisible{
	color: rgb(6 6 6 / 45%) !important;
}

.anticon-eye-invisible:hover{
	color: rgba(6, 6, 6, 60%) !important;
}

.ant-message-notice-content{
	color: #989898 !important;
    background-color: #fff !important;
}

.ant-modal-content{
    border-radius: 0 !important;
    padding: 0 !important;
}

.ant-modal-title{
    text-align: left;
    padding-left: 24px;
    padding-bottom: 16px;
    color: #262626 !important;
    font-weight: bold;
}

.ant-modal-body{
    padding: 16px 24px 0 24px !important;
}

.ant-modal-footer{
    padding: 16px 24px !important;
}

.ant-modal-footer button{
    border-radius: 4px !important;
}

.ant-modal-header{
    padding-top: 16px !important;
}

.anticon-loading > svg
{
    width: inherit !important;
    height: inherit !important;
}

input{
	color: #989898 !important;
}

button[role="switch"]{
    background: rgba(0, 0, 0, 0.45);
}

input:focus{
	color: #000000 !important;
}

.copy-icon svg{
    fill: #1890FF;
}

.delete-icon svg{
    fill: #F5222E;
}

.anticon-check-circle svg
{
    fill: #52C41A;
}