td > a{
    text-decoration: underline !important;
}

button.status {
    cursor: default !important;
    border-radius: 4px !important;
    &.ready, &.active {
        border-color: #B7EB8F;
        background-color: #F6FFED;
        > span {
            color: #52C41A !important;
        }
    }

    &.inactive {
        border-color: #D9D9D9;
        background-color: #F5F5F5;
        > span {
            color: #595959 !important;
        }
    }

    &.in-process {
        border-color: #91D5FF;
        background-color: #E6F7FF;
        > span {
            color: #1890FF !important;
        }
    }

}