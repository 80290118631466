.ant-pagination-item{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 5px !important;
}

.items__tab{

    .ant-table-cell > span{
        height: 40px;
        line-height: 40px;
    }
}